<template>
    <input v-model="val" type="date"  class="form-control form-control-sm" />
</template>
<script>
export default {
    props: ['antecedente','value'],
    data () {
        return{
            val:this.value,
        }
    },
    watch:{
        val:function(){
            this.$emit('input',this.val);
        }
    }
}
</script>


