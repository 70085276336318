import Service from "./Service";
const baseurl = '/api/embarazo';

export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showById(id) {
        return Service.get(`${baseurl}/show-by-idUsuario/${id}`);
    },
    showByLast(id) {
        return Service.get(`${baseurl}/show-by-last/${id}`);
    },
    showAlturaUterina(id) {
        return Service.get(`${baseurl}/show-altura-uterina/${id}`);
    },
    showPresionArterial(id) {
        return Service.get(`${baseurl}/show-presion-arterial/${id}`);
    },
    showPesoTalla(id) {
        return Service.get(`${baseurl}/show-peso-talla/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    close(id){
        return Service.get(`${baseurl}/close/${id}`);
    }
}